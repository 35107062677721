import React, { Component } from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "shared/data/reducers";
import { setInitialRequestorConfig } from "shared/lib/requestorFunctions";
import { formattedMessageParams } from "shared/messages";
// @ts-ignore
import Logger from "shared/services/Logger";

import SpecialOffersList from "@components/BestOffer/List";
import pageWithIntl from "@components/PageWithIntl";
import FormattedMessage from "@components/UI/FormattedMessage";
import Modal from "@components/UI/Modal";
import PageSeo from "@components/Utils/PageSeo";

const { title } = defineMessages({
  title: formattedMessageParams("Homepage.title"),
});

interface Props {
  dispatch: Dispatch<any>;
  intl: IntlShape;
  fileServerData: any;
  textStorage: any;
  currentLanguage: string;
  isClientInitSuccessful: boolean;
}

class Homepage extends Component<Props> {
  static getInitialProps = async (initialProps) => {
    try {
      const { domain } = await setInitialRequestorConfig(initialProps);
      return { domain };
    } catch (e) {
      Logger.error(`Error Initial index: ${JSON.stringify(e)}`);
      return {};
    }
  };

  render() {
    const { isClientInitSuccessful, intl } = this.props;

    const isClientInitFailed = !isClientInitSuccessful;

    if (isClientInitFailed) {
      return (
        <Modal isActive={isClientInitFailed} isCloseButtonHidden>
          <div
            style={{
              padding: 20,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              maxHeight: 170,
            }}
          >
            <FormattedMessage id="Alert.applicationNotActive" />
          </div>
        </Modal>
      );
    }

    return (
      <div className="container">
        <PageSeo
          title={{
            defaultTitle: intl.formatMessage(title),
            hideDefaultTitleIfCustomTitleExists: true,
          }}
        />

        <SpecialOffersList />
      </div>
    );
  }
}

export default connect((state: AppState) => ({
  isClientInitSuccessful: state.client.isInitSuccessful,
  fileServerData: state.requestorConfig.fileServerData,
  textStorage: state.requestorConfig.textStorage,
  currentLanguage: state.requestorConfig.currentLanguage,
  celejstateLoad: state.loading.universalLoader,
}))(pageWithIntl(Homepage));
