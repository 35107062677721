type PriceFormatterProps = {
  currency?: string;
  currencySymbol?: string;
};

type PriceFormatterOptuions = {
  decimalSeparator: string;
  thousandSeparator: string;
  fixedDecimalScale: boolean;
  decimalScale: number;
  isTooWide: boolean;
};

export const priceFormatter = ({
  currency,
  currencySymbol,
}: PriceFormatterProps): PriceFormatterOptuions => {
  const formatOptions = {
    RSD: {
      decimalSeparator: ",",
      thousandSeparator: ".",
      fixedDecimalScale: true,
      decimalScale: 2,
      suffix: currencySymbol ? ` ${currencySymbol}` : " ",
      isTooWide: true,
    },
    AUD: {
      decimalSeparator: ".",
      thousandSeparator: ",",
      fixedDecimalScale: true,
      suffix: currencySymbol ? ` ${currencySymbol}` : " AUD",
      decimalScale: 0,
      isTooWide: false,
    },
    DEFAULT: {
      decimalSeparator: ".",
      thousandSeparator: " ",
      fixedDecimalScale: false,
      decimalScale: 0,
      suffix: currencySymbol ? ` ${currencySymbol}` : " ",
      isTooWide: false,
    },
  };

  const currentFormatOption = formatOptions[currency] || formatOptions.DEFAULT;

  return currentFormatOption;
};
