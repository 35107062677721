import React from "react";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";
import { priceFormatter } from "shared/lib/priceFormatter";

type Props = {
  price: number | string;
  customCurrency?: string;
  customClass?: string;
  id?: string;
  newLine?: boolean;
};

function PriceFormatter({
  price,
  customCurrency,
  customClass,
  id,
  newLine = false,
}: Props) {
  const { currency, currentLanguage } = useSelector(
    (state: AppState) => state.requestorConfig
  );

  const currencyUsed = customCurrency?.length > 0 ? customCurrency : currency;

  const currencySymbol =
    currencyUsed === "CZK" && currentLanguage === "cs" ? "Kč" : currencyUsed;

  const currentFormatOption = priceFormatter({ currency, currencySymbol });

  return (
    <NumericFormat
      className={customClass}
      decimalScale={currentFormatOption.decimalScale}
      value={price?.toString()}
      displayType="text"
      decimalSeparator={currentFormatOption.decimalSeparator}
      thousandSeparator={currentFormatOption.thousandSeparator}
      suffix={currentFormatOption.suffix}
      fixedDecimalScale={currentFormatOption.fixedDecimalScale}
      renderText={(formattedValue) => {
        const lastWhitespaceIndex = formattedValue.lastIndexOf(" ");
        const valueWithoutCurrency = formattedValue.slice(
          0,
          lastWhitespaceIndex
        );
        const currencyText = formattedValue.slice(lastWhitespaceIndex);

        return (
          <span id={id}>
            {newLine ? (
              <>
                <div>
                  {valueWithoutCurrency} {currencyText}
                </div>
              </>
            ) : (
              formattedValue
            )}
          </span>
        );
      }}
    />
  );
}

export default PriceFormatter;
